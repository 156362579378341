export default defineNuxtRouteMiddleware(async (to) => {
  const auth = useAuth()
  const { env } = useRuntimeConfig().public

  /**
   * Nuxt Studio preview mode
   */
  if ((to.path.startsWith('/academy') && to.query.preview) || useCookie('previewToken').value) {
    return
  }

  if (to.path.includes('/academy') && env === 'staging') {
    return
  }

  if (!auth.loggedIn) {
    auth.log('middleware', 'not logged in')
    auth.setRedirectTo(to.fullPath)
    // we either try to hydrate the session from a payload within local storage and async the auth
    // OR we sync wait for the auth check if no payload exists
    await auth.check({
      from: 'middleware',
      swr: true,
      redirect: true,
    })
  }
})
